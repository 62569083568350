import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    TemplateID: {
        required: true,
        name: 'Template ID',
        format: /^\d+$/i,
    },
    Description: {
        name: 'Description',
        format: /^..+$/i,
    },
    SourceEmailName: {
        name: 'Email from name',
    },
    SourceEmailSubject: {
        name: 'Email subject line',
    },
    SourceEmail: {
        name: 'Email from address',
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    },
    SourceEmailAlias: {
        // not required
        name: 'Email from alias',
    },
    SourceSMSName: {
        name: 'SMS from name',
        format: /^[a-zA-Z 0-9\-]*$/i,
    },
    SourceSMS: {
        name: 'SMS from number',
        replaces: [
            {
                format: /^\+?\d{0,14}\(?\d{1,4}\)?[-\s\d]*$/,   // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                              // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                    //     на value
            },
        ],
        format: /^(\+?\d{3,14})?$/,                             // после всех предпроверок и замен - value должно соответсвовать format
        custom_message: 'Sorry, that\'s not a valid mobile number',
    },
    RepliesEmailDestination: {
        required: true,
        name: 'Replies E-mail destination',
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    },
    RepliesSMSDestination: {
        required: true,
        name: 'Replies SMS destination',
        replaces: [
            {
                format: /^\+?\d{0,14}\(?\d{1,4}\)?[-\s\d]*$/,   // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                              // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                    //     на value
            },
        ],
        format: /^(\+?\d{3,14})?$/,                             // после всех предпроверок и замен - value должно соответсвовать format
        custom_message: 'Sorry, that\'s not a valid mobile number',
    },
    RepliesWebhookURL: {
        required: true,
        name: 'Replies Webhook URL',
    },
})